<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div>
      <div class="d-flex justify-content-end">
        <b-button-group>
          <b-button @click="newFolder" size="sm" variant="outline-primary">
            New Folder
          </b-button>
          <b-button @click="uploadFiles" size="sm" variant="outline-primary">
            Upload Files
          </b-button>
        </b-button-group>
      </div>

      <div id="breadcrumb-alignment" class="mb-1" v-if="foldersData.length > 0">
        <div id="menu" class="d-flex flex-column align-items-left">
          <b-breadcrumb>
            <b-breadcrumb-item v-for="(item, i) in folderTemp" :key="i" :class="item.active == true ? 'active' : ''" @click="menuClick(item)">
              <feather-icon icon="HomeIcon" class="mr-50 mb-25" v-if="i == 0" />
              {{ item.text }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>

      <div id="breadcrumb-alignment" class="mb-1 no-file-message" v-else>
        No Files
      </div>

      <div v-if="foldersData.length > 0">
        <div class="custom-grid">
          <div v-for="x in foldersData" v-if="x.folderId == folderId" :key="x.type + x.id" class="custom-card">
            <div @contextmenu.prevent="handleClick($event, x.type, x)" @click="itemClick(x)" class="custom-card-content">
              <img :src="x.type == 'folder' ? '/folder.png' : '/file.png'" :alt="x.type" class="custom-card-image" />
              <div class="custom-card-footer" v-if="x.type == 'folder'">{{ truncateText(x.folderName, 64) }}</div>
              <div class="custom-card-footer" v-if="x.type == 'file'">{{ truncateText(x.fileName, 64) }}</div>
            </div>
          </div>
        </div>

        <vue-context ref="rightClickData">
          <li v-for="data in rightClickData" :key="data.text">
            <b-link class="d-flex align-items-center" @click="optionClicked(data)">
              <feather-icon :icon="data.icon" size="16" />
              <span class="ml-75">{{ data.text }}</span>
            </b-link>
          </li>
        </vue-context>
      </div>

      <!-- Add New Folder -->
      <b-modal id="modal-new-folder" @ok="handleFolderOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save Folder" centered title="Add New Folder">
        <validation-observer ref="folderRules">
          <b-form>
            <b-form-group>
              <label for="folderName">Folder Name:</label>
              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Add New Files -->
      <b-modal id="modal-new-file" @ok="handleFileOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save File" centered title="Add New Files">
        <validation-observer ref="fileRules">
          <b-form>
            <b-form-group>
              <validation-provider name="File" #default="{ errors }" rules="required">
                <b-form-file type="file" v-model="modalFiles" multiple placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>

        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <!-- Edit Folder  -->

      <b-modal id="modal-edit-folder" @ok="handleFolderUpdate" @hidden="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update Folder" centered title="Edit Folder">
        <validation-observer ref="folderRules">
          <b-form>
            <b-form-group>
              <label for="folderName">Folder Name:</label>
              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Edit File Name -->
      <b-modal id="modal-edit-file" @ok="handleFileUpdate" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update File" centered title="Edit New Files">
        <validation-observer ref="fileRules">
          <b-form>
            <b-form-group>
              <validation-provider name="File Name" #default="{ errors }" rules="required">
                <b-form-input id="fileName" type="text" v-model="modalFileName" placeholder="File Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue';
import store from '@/store';
import vSelect from 'vue-select';
import axiosIns from '@/libs/axios';
import VueContext from 'vue-context';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    BOverlay,

    vSelect,
    AppCollapse,
    AppCollapseItem,
    VueContext,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  props: {
    projectModalId: {
    type: Number,
  },

  projectFolders: {
    type: Array ,
  },

  },

  data() {
   const cardId = this.projectModalId ?? null;
    const tempcardId = router.currentRoute.params.id ? parseInt(router.currentRoute.params.id)   : cardId ;

    return {
      rightClickData: [],
      loading: false,
      fileURL: store.state.app.fileURL,

      folderId: 0,
      modalFolderName: null,
      modalFiles: null,
      modalFile: [],
      percentCompleted: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      directoryPath: '',
      cardId: tempcardId,

      folderTemp: [
        {
          text: 'Main',
          id: 0,
          active: true,
          cardId: tempcardId,
        },
      ],

      foldersData: [],
    };
  },

  created() {
    if(this.projectFolders == null){
      this.fetchFolders();

    }
    else{
      this.foldersData = this.projectFolders;
    }
  },

  methods: {
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      } else {
        return text;
      }
    },

    fetchFolders() {
      this.loading = true;
      store
        .dispatch('todo/getProjectFolders', { cardId: this.cardId })
        .then((res) => {
          this.foldersData = res.data;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    newFolder() {
      this.$bvModal.show('modal-new-folder');
    },

    uploadFiles(val) {
      this.$bvModal.show('modal-new-file');
    },

    handleClick(event, type, item) {
      if (type == 'folder') {

        this.rightClickData = [
          { icon: 'FileIcon', text: 'Open', data: item },
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
        ];
        this.$refs.rightClickData.open(event, item);
      } else if (type == 'file') {
        this.rightClickData = [
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
          { icon: 'SaveIcon', text: 'Save', data: item },
        ];

        this.$refs.rightClickData.open(event, item);
      }
    },

    menuClick(val) {
      this.folderId = val.id;

      if (val.id == 0) {
        this.folderTemp = [
          {
            text: 'Main',
            id: 0,
            active: true,
            companyId: -1,
          },
        ];
      } else {
        var tempFolder = [];
        var finished = false;
        this.folderTemp.forEach((element) => {
          if (finished == false) {
            if (element.id == val.id) {
              tempFolder.push({
                text: element.text,
                id: element.id,
                active: true,
                companyId: element.companyId,
              });
              this.folderTemp = tempFolder;
              finished = true;
            } else {
              tempFolder.push(element);
            }
          }
        });
      }
    },

    optionClicked(val) {
      switch (val.text) {
        case 'Open':
          this.itemClick(val.data);
          break;

        case 'Edit':
          if (val.data.type == 'folder') {
            console.log(val);
            this.modalFolderName = val.data.folderName;
            this.selectFolderId = val.data.id;

            this.$bvModal.show('modal-edit-folder');
          } else {
            this.modalFileName = val.data.fileName;
            this.selectFileId = val.data.id;

            this.$bvModal.show('modal-edit-file');
          }

          break;

        case 'Save':
          // const url = window.URL.createObjectURL(new Blob([this.fileURL + val.data.fileLink]));
          this.loading = true;

          this.directoryPath = '';
          this.folderTemp.forEach((element) => {
            this.directoryPath += element.text + '/';
          });

          axiosIns
            .post('downloadProjectFile', { link: val.data.fileLink }, { responseType: 'blob' })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));

              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', val.data.fileLink);

              document.body.appendChild(link);
              link.click();
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });

          // const url = fetch(this.fileURL + val.data.fileLink).then((response) => response.blob());

          // window.location.href = this.fileURL + val.data.fileLink;
          break;

        case 'Delete':
          if (val.data.type == 'file') {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this file?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFile/${val.data.id}`)
                    .then((res) => {
                      this.foldersData = res.data;
                      this.totalRows = res.data.length;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ File has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log('error delete ', error);
                      this.loading = false;
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this folder?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFolder/${val.data.id}`)
                    .then((res) => {
                      this.foldersData = res.data;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ Folder has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });

                      this.loading = false;
                    })
                    .catch((error) => {
                      this.loading = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'An error occurred',
                          text: 'Please try again later or contact support.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      });
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }

          break;

        default:
          break;
      }
    },

    itemClick(val) {
      if (val.type == 'folder') {
        this.folderTemp.forEach((element) => {
          element.active = false;
        });
        this.folderTemp.push({
          id: val.id,
          text: val.folderName,
          active: true,
          companyId: val.companyId,
        });

        this.topMenuId = val.id;
        this.folderId = val.id;
      }
    },

    handleFolderOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.$refs.folderRules.validate().then((success) => {
        if (success) {
          var tempData = {
            folderName: this.modalFolderName,
            topMenuId: this.folderId,
            cardId: this.cardId,
            status: 'A',
          };

          this.loading = true;

          store
            .dispatch('todo/saveProjectFolder', tempData)
            .then((res) => {
              this.foldersData = res.data;

              this.$bvModal.hide('modal-new-folder');
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Folder has been created',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    handleFolderUpdate(bvModalEvent) {
      // Prevent modal from closing

      bvModalEvent.preventDefault();

      var data = {
        id: this.selectFolderId,
        folderName: this.modalFolderName,
        topMenuId: this.folderId,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFolderUpdate(data);
    },

    handleFileUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      var data = {
        id: this.selectFileId,
        fileName: this.modalFileName,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFileUpdate(data);
    },

    handleFileOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.fileRules.validate().then((success) => {
        if (success) {
          if (this.percentCompleted == 0) {
            this.directoryPath = '';
            this.folderTemp.forEach((element) => {
              this.directoryPath += element.text + '/';
            });
            this.loading = true;
            this.uploadFilesSequentially(0);
          }
        }
      });
    },

    modalFileOk(tempData) {
      store
        .dispatch('todo/saveProjectFile', tempData)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFile = [];
          this.modalFolderName= null;
          this.modalFiles= null;
          this.percentCompleted= 0;

          this.$bvModal.hide('modal-new-file');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ File has been uploaded',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    uploadFilesSequentially(index) {
      if (index < this.modalFiles.length) {
        let element = this.modalFiles[index];
        let formData = new FormData();

        formData.append('file', element);
        formData.append('path', this.directoryPath);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (this.modalFiles.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);
            if (index === this.modalFiles.length - 1) {
              this.percentCompleted = 100;
            }

            if (this.percentCompleted === 100) {
              var data = {
                topMenuId: this.folderId,
                fileDetails: {
                  fileName: this.modalFile,
                  fileLink: this.directoryPath,
                },

                cardId: this.cardId,
                status: 'A',
              };

              this.modalFileOk(data);
            }

            this.uploadFilesSequentially(index + 1);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },

    modalFolderUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-folder');
      });

      this.loading = true;

      store
        .dispatch('todo/updateProjectFolder', data)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFolderName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    modalFileUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-file');
      });

      this.loading = true;

      store
        .dispatch('todo/updateProjectFile', data)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFileName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    resetModal() {
      this.modalFolderName = null;
      this.modalFiles = null;
    },

    fileSelected(event) {
      this.directoryPath = '';
      this.folderTemp.forEach((element) => {
        this.directoryPath += element.text + '/';
      });

      var total = event.length;
      event.forEach((element, i) => {
        let formData = new FormData();

        formData.append('file', element);
        formData.append('path', this.directoryPath);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (event.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);

            total = total - 1;

            if (total == 0) {
              this.percentCompleted = 100;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  gap: 5px;
  // padding: 50px;
}

.custom-card {
  // border: 1px solid #e0e0e0;
  // border-radius: 5px;
  // background-color: #f5f5f5;
  transition: background-color 0.2s ease; /* Hover efekti için geçiş ekledik */
}

.custom-card-content {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.custom-card:hover {
  background-color: #e0e0e080; /* Hover efekti için arka plan rengi değişiyor */
}

.custom-card-image {
  width: 64px;
}

.custom-card-footer {
  font-size: 12px;
  text-align: center;
  // padding: 5px 0;
}
.active a {
  color: #6e6b7b;
  cursor: auto;
  text-decoration: none;
}

.no-file-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
