<template>
  <b-overlay rounded="sm" no-fade>
    <div v-if="isProjectSidebarActive">
      <project-sidebar :is-project-sidebar-active="isProjectSidebarActive" :deck-options="deckOptions" :project-modal-id="projectModalId" v-model="isProjectSidebarActive" @emit-get-projects="emitGetProjects" />
    </div>

    <div class="row flex-nowrap width-scrool" style="overflow-x: auto;">
      <div class="custom-column" v-for="(board, index) in deckOptions" :key="'board_' + index">
        <div class="text-white customer-column-header" :style="`background-color: ${board.bgColor}`">
          <div class="d-flex justify-content-between align-items-center">
            <span class="ml-1" style="font-weight: 700;">{{ board.name }}</span>
            <feather-icon class="float-right mr-25 board-title" v-b-modal.modal-create-task icon="MoreHorizontalIcon" size="16" :id="'board-popover-' + index" />
          </div>

          <b-popover :target="'board-popover-' + index" triggers="blur focus" variant="primary" placement="rightbottom">
            <div class="d-flex flex-column ">
              <span v-if="$Can('board_add_btn')" class="popoverMenu" @click="editBoard(board)"> Edit Board </span>

              <span v-if="$Can('board_remove_btn')" @click="removeBoard(board)" class="text-danger popoverMenu">
                Remove Board
              </span>
            </div>
          </b-popover>
        </div>

        <div id="container" class="scroll-container">
          <draggable :id="board.name" class="list-group" tag="div" v-model="board.list" v-bind="dragOptions" @start="drag = true" @end="endDrag">
            <b-card class="ml-50 mr-50" :id="card.id" v-for="(card, cardIndex) in filteredProjects" v-if="board.name == card.status" :key="cardIndex">
              <feather-icon icon="MoreVerticalIcon" size="16" style="position: absolute; top: 10px; right: 5px; cursor: pointer;" :id="'card-popover-' + cardIndex" />

              <b-popover :target="'card-popover-' + cardIndex" triggers="blur focus" variant="primary" placement="rightbottom">
                <div class="d-flex flex-column ">
                  <span v-show="!card.hidden" v-if="card.archived" class="popoverMenu" @click="archiveProject(card.id, false)"> Activate Project </span>
                  <span v-show="!card.hidden" v-else class="popoverMenu" @click="archiveProject(card.id, true)"> Archive Project </span>

                  <!-- <span v-show="!card.archived" v-if="card.hidden" class="popoverMenu" @click="hideProject(card.id, false)"> Unhide Project </span>
                  <span v-show="!card.archived" v-else class="popoverMenu" @click="hideProject(card.id, true)"> Hide Project </span> -->

                  <span v-if="!card.archived && !card.hidden" v-for="(i, index) in deckOptions" :key="index" class="popoverMenu" @click="changeStatus(card.id, i)"> {{ i.name }}</span>
                </div>
              </b-popover>

              <div @click="activateSidebar(card.id)" style="min-height: 200px;">
                <div class="text-center">
                  <h5 class="card-title">
                    {{ card.name }}
                  </h5>
                  <span class="card-description">
                    <div class="d-flex flex-wrap">
                      <div v-for="(user, userIndex) in card.userList" :key="userIndex" class="avatar-circle mr-25">
                        <img :src="profileURL + user.profile" alt="User Image" height="20px" class="avatar-circle-inner" />
                      </div>
                    </div>
                  </span>
                  <p class="text-danger mt-50" v-if="card.startDate != null">{{ formatDate(card.startDate) }}</p>
                  <p class="text-danger  mt-50" v-else>No Start Date</p>
                </div>

                <div class="text-center">
                  <div style="height: 3rem;">
                    <div class="stats-container">
                      <div class="stat">
                        <div class="number">{{ card.completedTask }}</div>
                        <div class="label">Completed</div>
                      </div>
                      <div class="stat">
                        <div class="number">{{ card.totalTask }}</div>
                        <div class="label">Total</div>
                      </div>
                    </div>

                    <b-progress :max="card.totalTask">
                      <b-progress-bar :value="card.completedTask" :label="`${Math.round((card.completedTask / card.totalTask) * 100)}%`" variant="primary" />
                    </b-progress>
                  </div>
                </div>

                <div class="text-right mt-2">
                  <span class="float-md-right d-none d-md-block hoverable-container" style="color:gray">
                    <feather-icon class="float-right" v-b-modal.modal-create-task icon="MessageCircleIcon" size="20" style="cursor: pointer; color:gray" />
                    {{ card.commentCount }}
                  </span>
                </div>
              </div>
            </b-card>
          </draggable>
        </div>
      </div>

      <div class="custom-column" v-if="$Can('board_add_btn')">
        <div class="text-white customer-column-header" style="cursor:pointer; border:solid 1px black;" @click="newBoard">
          <div class="d-flex justify-content-between align-items-center text-dark">
            <span class="ml-1" style="font-weight: 400;">Add board</span>
            <feather-icon class="float-right mr-25" v-b-modal.modal-create-task icon="PlusCircleIcon" size="16" />
          </div>
        </div>
      </div>
    </div>

    <b-modal id="board-create" cancel-variant="outline-secondary" ok-title="New Board" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="New Board">
      <b-form ref="boardRules">
        <b-form-group :state="nameState" invalid-feedback="Board Name is required">
          <label for="boardName">Board Name:</label>
          <b-form-input id="boardName" type="text" :state="nameState" v-model="modalBoardName" required placeholder="Board Name" />
        </b-form-group>
        <app-color-palette-vue @update:selected-color="colorSelectedHandler" :row-number="8"></app-color-palette-vue>
      </b-form>
    </b-modal>

    <b-modal id="board-update" cancel-variant="outline-secondary" ok-title="Edit Board" @ok="handleUpdate" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Edit Board">
      <b-form ref="boardRules">
        <b-form-group :state="nameState" invalid-feedback="Board Name is required">
          <label for="boardName">Board Name:</label>
          <b-form-input id="boardName" type="text" :state="nameState" v-model="modalBoardName" required placeholder="Board Name" />
          <app-color-palette-vue @update:selected-color="colorSelectedHandler" :row-number="8"></app-color-palette-vue>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BCard, BCol } from 'bootstrap-vue';
import draggable from 'vuedraggable';
import axiosIns from '@/libs/axios';
import { VBPopover } from 'bootstrap-vue';

import ProjectSidebar from './ProjectSidebar.vue';
import AppColorPaletteVue from '@/@core/layouts/components/AppColorPalette.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

import { formatDate } from '../helpers';

export default {
  components: {
    BCol,
    BCard,
    draggable,
    ProjectSidebar,
    AppColorPaletteVue,
    ToastificationContent,
  },
  data() {
    return {
      modalBoardName: null,
      isProjectSidebarActive: false,
      projectModalId: null,
      selectedBgColor: null,
      modalBoardOldName: null,
      profileURL: store.state.app.profileURL,
    };
  },
  directives: {
    'b-popover': VBPopover,
  },

  props: {
    filteredProjects: {
      type: Array,
      required: true,
    },
    deckOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate,

    endDrag(evt) {
      this.drag = false;
      let projectStatus = this.filteredProjects.find((e) => e.id == evt.item.id).status;
      if (evt.to.id != projectStatus) {
        this.loading = true;
        axiosIns
          .post('updateCardStatus', {
            id: evt.item.id,
            status: evt.to.id,
          })
          .then((res) => {
            this.loading = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project Status has been updated',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    shouldEnableOverflow() {
      return this.deckOptions.length < 9;
    },
    newBoard() {
      this.$bvModal.show('board-create');
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var data = {
        name: this.modalBoardName,
        bgColor: this.selectedBgColor,
      };

      this.modalOk(data);
    },

    modalOk(data) {
      if (this.modalBoardName == null) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('board-create');
      });

      this.loading = true;

      axiosIns
        .post('createCardBoard', data)
        .then((res) => {
          this.modalBoardName = null;
          // this.totalRows = res.data.length;
          this.emitGetProjects();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    emitGetProjects() {
      this.$emit('get-projects');
    },
    resetModal() {
      this.modalBoardName = null;
      this.selectedBgColor = null;
    },

    editBoard(board) {
      this.modalBoardOldName = board.name;
      this.modalBoardName = board.name;
      this.selectedBgColor = board.bgColor.replace('#', '');

      this.$bvModal.show('board-update');
    },
    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      var data = {
        oldName: this.modalBoardOldName,
        name: this.modalBoardName,
        bgColor: this.selectedBgColor,
      };

      this.modalUpdate(data);
    },

    modalUpdate(data) {
      if (this.modalBoardName == null) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('board-update');
      });

      this.loading = true;

      axiosIns
        .post('updateCardBoard', data)
        .then((res) => {
          this.modalBoardName = null;
          // this.totalRows = res.data.length;
          this.emitGetProjects();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    removeBoard(board) {
      axiosIns
        .post('deleteCardBoard', { boardId: board.id })
        .then((res) => {
          if (res.data == 'Failed') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Board can not be removed',
                text: 'Please empty the board before removing it.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Board has been removed.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
            this.emitGetProjects();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    activateSidebar(id) {
      if (this.$Can('card_sidebar')) {
        this.projectModalId = id;
        if (this.projectModalId == id) {
          this.isProjectSidebarActive = true;
        }
      }
    },

    colorSelectedHandler(textColor, bgColor) {
      this.selectedTextColor = textColor;
      this.selectedBgColor = bgColor;
    },

    archiveProject(id, val) {
      this.loading = true;
      axiosIns
        .post('archiveCard', {
          id: id,
          archived: val,
        })
        .then((res) => {
          this.loading = false;
          if (val) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been archived.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been activated.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          }
          this.emitGetProjects();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    hideProject(id, val) {
      this.loading = true;
      console.log(val);
      axiosIns
        .post('hideCard', {
          id: id,
          hidden: val,
        })
        .then((res) => {
          this.loading = false;
          if (val) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been hidden.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been unhidden.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          }
          this.emitGetProjects();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    changeStatus(id, val) {
      this.loading = true;
      axiosIns
        .post('updateCardStatus', {
          id: id,
          status: val.name,
        })
        .then((res) => {
          this.emitGetProjects();
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Status has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getSectionBgColor(val) {
      const section = this.deckOptions.find((section) => section.name === val);
      return section ? section.bgColor : '#000000';
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },

    nameState() {
      return this.modalBoardName != null ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
  margin-top: 0.75rem;
  margin-bottom: 0rem;
}
.card .banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 125px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 20px 0px 20px;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.strikethrough {
  text-decoration: line-through;
}
</style>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.contact:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact-details {
  flex-grow: 1;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

.contact-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.contact:hover .contact-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
}

.stats-container {
  display: flex;
  justify-content: space-between;
}

.stat {
  text-align: center;
}

.number {
  font-size: 15px;
  font-weight: bold;
}

.label {
  font-size: 10px;
  color: #666; /* You can adjust the color to your preference */
}

.hoverable-container {
  padding: 10px;
}

.hoverable-container:hover {
  background-color: #f0f0f0;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  padding: 10px;
}

.card-title {
  font-weight: 600;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
  min-height: 40px;
}

.card-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  text-transform: capitalize;
}
.list-group-item {
  cursor: move;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

#container {
  flex-wrap: nowrap;
}

.list-group {
  min-height: 20px;
  // max-height: 100%;
}

.scroll-container {
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  background-color: #f2f2f4;
}

@media (max-width: 768px) {
  .scroll-container {
    height: calc(100vh - 250px);
  }
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.scroll-container:hover::-webkit-scrollbar:hover {
  background-color: #aaa;
}

.scroll-container:hover::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.scroll-container:hover::-webkit-scrollbar-thumb:hover {
  border: 0;
  background-color: #555;
}

//bottom scrool
.width-scrool::-webkit-scrollbar {
  height: 8px;
  background-color: transparent;
}

.width-scrool:hover::-webkit-scrollbar:hover {
  background-color: #aaa;
}

.width-scrool:hover::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.width-scrool:hover::-webkit-scrollbar-thumb:hover {
  border: 0;
  background-color: #555;
}

.board-title {
  cursor: pointer;
  color: white;
}

.board-title:hover {
  cursor: pointer;
  color: #555;
}

.custom-column {
  // border: solid black 1px;
  box-sizing: border-box;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 300px;
  min-width: 300px;
}

.customer-column-header {
  padding: 0.2rem;
}
</style>
